@import "../../../../../config.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-4);
  @media screen and (min-width: $dsm-breakpoint-md) {
    width: 50%;
  }

  .form__row {
    display: flex;
    gap: var(--dsm-spacing-px-8);

    * {
      width: 50%;
    }
  }
}

.create-farm {
  margin-top: var(--dsm-spacing-px-2);
}

.loader {
  display: flex;
  justify-content: center;
}
