@import "../../../config.scss";

.data-container {
  height: 96px;
  overflow: hidden;
  page-break-after: always;

  @media print {
    height: auto;
  }

  &--open {
    overflow: visible;
  }

  &__panel {
    z-index: 4;
    position: relative;
    background-color: white;
    box-shadow:
      0px 6px 10px 4px rgba(0, 0, 0, 0.15),
      0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  }

  &__open {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    gap: var(--dsm-spacing-px-2);
    border-bottom: 1px solid #dddddd;
    font-weight: 500;
    cursor: pointer;

    @media print {
      display: none;
    }

    dsm-icon {
      width: var(--dsm-spacing-px-5);
    }
  }

  &__hide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--dsm-spacing-px-2) 0;
    font-weight: 500;
    cursor: pointer;

    @media print {
      display: none;
    }

    dsm-icon {
      width: var(--dsm-spacing-px-5);
      margin-right: var(--dsm-spacing-px-1);
    }
  }

  &__edit {
    position: absolute;
    right: 8px;
    bottom: 8px;

    @media print {
      display: none;
    }
  }
}

.data {
  display: grid;
  grid-template-columns: repeat(2, 50%);

  @media screen and (min-width: $dsm-breakpoint-lg) {
    grid-template-columns: repeat(5, 20%);
  }

  &__sub-header {
    margin: 0 var(--dsm-spacing-px-4);
  }

  &__cell {
    grid-column: span 1;
    padding: var(--dsm-spacing-px-4);
    word-wrap: break-word;

    @media screen and (min-width: $dsm-breakpoint-lg) {
      padding: var(--dsm-spacing-px-5);
    }

    @media screen and (max-width: $dsm-breakpoint-lg-max) {
      &:empty {
        display: none;
      }
    }

    @media print {
      &:empty {
        display: none;
      }
    }

    &--edit-vertical {
      display: flex;
      flex-direction: column;
      gap: var(--dsm-spacing-px-1);
    }

    &--edit-horizontal {
      display: flex;
      gap: var(--dsm-spacing-px-1);
    }

    &--remark {
      grid-column: span 2;

      @media screen and (min-width: $dsm-breakpoint-lg) {
        grid-column: span 3;
      }
    }

    b,
    span {
      display: block;
    }
  }
}

.results {
  display: flex;
  flex-direction: column;
  gap: var(--dsm-spacing-px-5);

  @media print {
    flex-direction: row;
  }

  @media screen and (min-width: $dsm-breakpoint-lg) {
    flex-direction: row;
  }

  &__side {
    display: flex;
    flex-direction: column;
    gap: var(--dsm-spacing-px-5);
  }
}

.individual-results {
  padding: var(--dsm-spacing-px-2);
  padding-right: var(--dsm-spacing-px-2);
  background-color: var(--dsm-color-basic-white);

  @media print {
    border: 1px solid var(--dsm-color-neutral);
    border-radius: var(--dsm-spacing-px-2);
  }

  @media print {
    margin-bottom: var(--dsm-spacing-px-8);
  }

  h5 {
    margin-bottom: var(--dsm-spacing-px-6);

    @media print {
      margin-bottom: var(--dsm-spacing-px-2);
    }
  }
}

.sub-header {
  display: flex;
  justify-content: space-between;
  margin-top: var(--dsm-spacing-px-6);
  page-break-before: always;

  @media print {
    dsm-button-group {
      display: none;
    }
  }
}

.main-results {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: var(--dsm-spacing-px-6);
  gap: var(--dsm-spacing-px-3);

  @media screen and (max-width: $dsm-breakpoint-sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.results-summary {
  margin-bottom: var(--dsm-spacing-px-6);
  padding: var(--dsm-spacing-px-6);
  border-right: var(--dsm-spacing-px-1) solid var(--dsm-color-basic-black);
  border-left: var(--dsm-spacing-px-1) solid var(--dsm-color-basic-black);
  background-color: var(--dsm-color-basic-white);

  @media print {
    border: 1px solid var(--dsm-color-neutral);
    border-radius: var(--dsm-spacing-px-2);
  }

  h5 {
    margin-bottom: var(--dsm-spacing-px-1);
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 160%;
  }

  &__level {
    margin-bottom: var(--dsm-spacing-px-4);
    padding-left: 14px;
  }
}

.health-message {
  margin-bottom: var(--dsm-spacing-px-5);
}

.hyd-alert {
  margin-bottom: var(--dsm-spacing-px-5);

  ul {
    margin: 0;
  }
}

.card-header__container {
  display: flex;
  justify-content: space-between;
}

.card--sample-table {
  border-bottom: none;

  dsm-table {
    margin: 0 var(--dsm-spacing-px-6-minus) var(--dsm-spacing-px-6-minus) var(--dsm-spacing-px-6-minus);
  }
}

.scatter {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 8px;
    left: 0;
    height: calc(100% - 7px - 8px);
    content: "";
  }
}

.table {
  border-radius: var(--dsm-spacing-px-1);
  background-color: var(--dsm-color-basic-white);
  page-break-before: always;
}

.hide {
  display: none;

  @media print {
    display: block;
  }
}

.print-footer {
  display: none;

  @media print {
    display: flex;
    position: fixed;
    bottom: var(--dsm-spacing-px-4);
    justify-content: space-between;
    width: calc(100% - var(--dsm-spacing-px-16));

    img {
      height: var(--dsm-spacing-px-4);
    }
  }

  @media screen and (min-width: $dsm-breakpoint-lg) {
    display: none;
  }
}

.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--dsm-spacing-px-1-minus);
  margin-right: var(--dsm-spacing-px-2);
  padding-bottom: var(--dsm-spacing-px-1);
  gap: var(--dsm-spacing-px-3);

  &__average {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 59px;

    &::before {
      display: inline-block;
      width: var(--dsm-spacing-px-3);
      margin-right: var(--dsm-spacing-px-1);
      border-bottom: 1px dashed;
      content: "";
    }
  }

  &__item {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::before {
      display: inline-block;
      width: var(--dsm-spacing-px-3);
      height: var(--dsm-spacing-px-3);
      margin-right: var(--dsm-spacing-px-1);
      border-radius: 50%;
      content: "";
    }

    &--optimum {
      &::before {
        background: linear-gradient(135deg, #084337 50%, #76d0c2 50%); //TODO use color variable
      }
    }

    &--adequate {
      &::before {
        background: linear-gradient(135deg, #44b4a1 50%, #c3e6e1 50%); //TODO use color variable
      }
    }

    &--insufficient {
      &::before {
        background: linear-gradient(135deg, #f08b1f 50%, #ffaa4d 50%); //TODO use color variable
      }
    }

    &--deficient {
      &::before {
        background: linear-gradient(135deg, #d92d20 50%, #e67f7c 50%); //TODO use color variable
      }
    }
  }
}
