.graph-panel {
  position: relative;
  margin-bottom: var(--dsm-spacing-px-5);
}

.selected-samples-header {
  display: flex;
  justify-content: space-between;
}

.selected-samples {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--dsm-spacing-px-5);
  gap: var(--dsm-spacing-px-2);
}

.box-plot {
  position: relative;

  &::before {
    z-index: 1;
    position: absolute;
    top: 44px;
    width: var(--dsm-spacing-px-1);
    height: 445px;
    margin-left: 70px;
    content: "";
  }

  &__empty {
    &::after {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--dsm-color-basic-black);
      content: "";
      opacity: 0.15;
    }
  }

  &__empty-text {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 1.3rem;
    text-align: center;
  }
}
